<template>
  <div class="ai-menubar" v-show="isCrop">
    <div
      :class="['item', index == cropIndex ? 'active' : '']"
      @click="loadCrop(item.mark)"
      v-for="(item, index) in cropList"
      :key="index"
      @mouseenter="mouseEnterCrop(index, item.mark)"
      @mouseleave="mouseLeaveCrop(index)"
    >
      <img :src="item.icon" alt="" class="img-icon" />
      <div :class="['item-title']">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aiSelectCrop",
  props: {
    isCrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cropIndex: null,
      cropList: [
        {
          bgImg: require("@/assets/image/ai/crop/xm_bg.jpg"),
          icon: require("@/assets/image/ai/crop/xm.png"),
          title: "小麦",
          mark: "xm",
        },
        {
          bgImg: require("@/assets/image/ai/crop/sd_bg.png"),
          icon: require("@/assets/image/ai/crop/sd.png"),
          title: "水稻",
          mark: "sd",
        },
        {
          bgImg: require("@/assets/image/ai/crop/dd_bg.jpg"),
          icon: require("@/assets/image/ai/crop/dd.png"),
          title: "大豆",
          mark: "dd",
        },
        {
          bgImg: require("@/assets/image/ai/crop/dp_bg.jpg"),
          icon: require("@/assets/image/ai/crop/dp.png"),
          title: "大棚",
          mark: "dp",
        },
      ],
    };
  },
  methods: {
    loadCrop(mark) {
      this.$emit("loadCrop", mark);
    },
    mouseEnterCrop(index, mark) {
      this.cropIndex = index;
      this.$emit("mouseEnterCrop", mark);
    },
    mouseLeaveCrop() {
      this.cropIndex = null;
    },
  },
};
</script>

<style lang="less" scoped>
.ai-menubar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .item {
    width: 221px;
    height: 284px;
    cursor: pointer;
    margin-right: 57px;
    text-decoration: none;
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 22px 0;
    background: url("../../../assets/image/ai/crop/crop_bg.png") center center
      no-repeat;
    background-size: cover;
    &:last-of-type {
      margin: 0;
    }
    &.active {
      transform: scale(1.1) translateY(-30px);
      background: url("../../../assets/image/ai/crop/crop_active.png") center
        center no-repeat;
      background-size: cover;
    }
    .img-icon {
      width: 88px;
      height: 88px;
      margin-bottom: 22px;
    }
    .item-title {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
  }
}
</style>
